<template>
  <div>
    <div class="row">
      <div class="col-12 mb-4">
        <h4 class="blue-text font-weight-bold">
          <router-link :to="{ name: 'adminRoute-users-list' }">
            <font-awesome-icon
              class="mr-3"
              :icon="{ prefix: 'fas', iconName: 'arrow-left' }"
            />
          </router-link>
          <span>管理者を新規追加</span>
        </h4>
      </div>
    </div>
    <form v-on:submit="validateCreateUser">
      <div class="row">
        <div class="col-12 col-md-12 col-lg-12 col-xl-6">
          <div class="row">
            <div class="col-12 col-md-6 col-lg-6 col-xl-6">
              <div class="mt-4">
                <p class="m-0 font-weight-bold">名前 (姓)</p>
                <input
                  id="first-name"
                  class="form-control shadow-1"
                  type="text"
                  minlength="1"
                  maxlength="100"
                  v-model="lastName"
                  v-on:keyup="formMessage = null"
                  placeholder="名前（姓）を入力してください"
                  required
                />
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-6 col-xl-6">
              <div class="mt-4">
                <p class="m-0 font-weight-bold">名前 (名)</p>
                <input
                  id="last-name"
                  class="form-control shadow-1"
                  type="text"
                  minlength="1"
                  maxlength="100"
                  v-model="firstName"
                  v-on:keyup="formMessage = null"
                  placeholder="名前（名）を入力してください"
                  required
                />
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-6 col-xl-6">
              <div class="mt-4">
                <p class="m-0 font-weight-bold">生年月日</p>
                <v-date-picker
                  v-model="birthDate"
                  :masks="{ L: 'YYYY-MM-DD' }"
                  locale="ja"
                  mode="date"
                  :max-date="new Date()"
                >
                  <template v-slot="{ inputValue, togglePopover }">
                    <div class="date-picker-wrap">
                      <input
                        :value="inputValue"
                        v-on:click="togglePopover"
                        class="form-control datepicker-input shadow-1"
                        placeholder="YYYY-MM-DD"
                        required
                      />
                    </div>
                  </template>
                </v-date-picker>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-6 col-xl-6">
              <div class="mt-4">
                <p class="m-0 font-weight-bold">性別</p>
                <b-form-select
                  class="shadow-1"
                  v-model="gender"
                  :options="getGender()"
                  required
                ></b-form-select>
              </div>
            </div>
            <div class="col-12">
              <div class="mt-4">
                <p class="m-0 font-weight-bold">
                  <span>都道府県</span>
                  <b-spinner small v-if="prefectures.loader"></b-spinner>
                </p>
                <b-form-select
                  class="shadow-1"
                  v-model="prefectures.value"
                  :options="prefectures.options"
                  required
                ></b-form-select>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-12 col-lg-12 col-xl-6">
          <div class="row">
            <div class="col-12">
              <div class="mt-4">
                <p class="m-0 font-weight-bold">メールアドレス</p>
                <input
                  id="email"
                  class="form-control shadow-1"
                  type="email"
                  minlength="2"
                  maxlength="100"
                  v-model="email"
                  v-on:keyup="formMessage = null"
                  placeholder="メールアドレスを入力して下さい"
                  required
                />
              </div>
            </div>
            <div class="col-12">
              <div class="mt-4">
                <p class="m-0 font-weight-bold">パスワードは</p>
                <div class="d-flex">
                  <input
                    id="new-password"
                    class="form-control d-inline shadow-1"
                    type="password"
                    v-model="newPassword"
                    v-on:keyup="hintChecker"
                    minlength="2"
                    maxlength="100"
                    placeholder="パスワードを入力して下さい"
                    style="
                      border-top-right-radius: 0 !important;
                      border-bottom-right-radius: 0 !important;
                      border-right: 0;
                    "
                    required
                  />
                  <button
                    class="btn btn-ds transparent m-0 d-inline shadow-1"
                    type="button"
                    style="
                      height: 56px;
                      width: 56px;
                      border-top-left-radius: 0;
                      border-bottom-left-radius: 0;
                      border-top: 1px solid #ced4da;
                      border-bottom: 1px solid #ced4da;
                      border-right: 1px solid #ced4da;
                    "
                    v-on:click="showPasswordToggle('new-password')"
                    v-waves.light
                  >
                    <font-awesome-icon
                      :icon="{
                        prefix: 'fas',
                        iconName: newPasswordIcon,
                      }"
                    />
                  </button>
                </div>
              </div>
              <b-tooltip
                target="new-password"
                placement="bottom"
                custom-class="passwordTooltip"
                variant="danger"
                :show.sync="passwordTooltip"
                :disabled.sync="disabledPasswordTooltip"
              >
                <div>
                  <p v-if="!rules.length">
                    パスワードは8文字以上必要です。
                  </p>
                  <p v-if="!rules.uppercase">
                    パスワードは1文字以上大文字が必要です。
                  </p>
                  <p v-if="!rules.lowercase">
                    パスワードは1文字以上小文字が必要です。
                  </p>
                </div>
              </b-tooltip>
            </div>
            <div class="col-12">
              <div class="mt-4">
                <p class="m-0 font-weight-bold">パスワード確認</p>
                <div class="d-flex">
                  <input
                    id="confirm-password"
                    class="form-control d-inline shadow-1"
                    type="password"
                    v-model="confirmPassword"
                    minlength="2"
                    maxlength="100"
                    placeholder="確認パスワードを入力してください"
                    style="
                      border-top-right-radius: 0 !important;
                      border-bottom-right-radius: 0 !important;
                      border-right: 0;
                    "
                    required
                  />
                  <button
                    class="btn btn-ds transparent m-0 d-inline shadow-1"
                    type="button"
                    style="
                      height: 56px;
                      width: 56px;
                      border-top-left-radius: 0;
                      border-bottom-left-radius: 0;
                      border-top: 1px solid #ced4da;
                      border-bottom: 1px solid #ced4da;
                      border-right: 1px solid #ced4da;
                    "
                    v-on:click="showPasswordToggle('confirm-password')"
                    v-waves.light
                  >
                    <font-awesome-icon
                      :icon="{
                        prefix: 'fas',
                        iconName: confirmPasswordIcon,
                      }"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <b-alert
            class="m-0 mt-3 light-grey-outline grey-text"
            variant="warning"
            show
            v-if="formMessage !== null"
          >
            {{ formMessage }}
          </b-alert>
        </div>
        <div class="col-12 col-md-12 col-lg-12 col-xl-6 mt-5">
          <div class="row mt-5">
            <div class="col-12 col-md-6 col-lg-6 col-xl-6">
              <router-link :to="{ name: 'adminRoute-users-list' }">
                <button
                  class="btn btn-ds light-grey white-text mb-2"
                  v-waves.light
                >
                  キャンセル
                </button>
              </router-link>
            </div>
            <div class="col-12 col-md-6 col-lg-6 col-xl-6">
              <button
                class="btn btn-ds dark-blue white-text mb-2"
                type="submit"
                v-waves.light
              >
                更新
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>

    <b-modal
      id="user-create-success-modal"
      class="border-radius-2"
      size="md"
      hide-footer
      hide-header
      no-close-on-backdrop
      no-close-on-esc
      centered
    >
      <div class="container text-center">
        <img class="mt-4" src="/img/icons/ico-info.svg" alt="ico-info" />
        <h5 class="mt-4 mb-0 dark-blue-text font-weight-bold">
          これで、新しいユーザーが正常に作成されました。
        </h5>
        <div class="row mb-3 mt-5">
          <div class="col-12">
            <router-link :to="{ name: 'adminRoute-users-list' }">
              <button
                class="btn btn-ds dark-blue white-text pt-2 pb-2"
                type="button"
                v-waves.light
              >
                OK
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
// Import Services
import {
  globalService_GetAllPrefectures,
  globalService_RegisterProfile,
} from '../../services/global';
import {
  adm_userService_CreateUserCustomer,
  adm_userService_CreateUserAdmin,
} from '../../services/admin/users';

// Import Moment JS
import moment from 'moment';

// Import Vue Calendar
import DatePicker from 'v-calendar/lib/components/date-picker.umd';

//Import Common

import { getGender } from '../../common/Utility';

export default {
  metaInfo() {
    return {
      title: 'Digital Staging - Dashboard',
    };
  },
  components: {
    'v-date-picker': DatePicker,
  },
  data() {
    return {
      firstName: null,
      lastName: null,
      birthDate: null,
      gender: null,
      prefectures: {
        value: null,
        options: [],
        loader: true,
      },
      email: null,
      newPassword: null,
      confirmPassword: null,
      newPasswordIcon: 'eye-slash',
      confirmPasswordIcon: 'eye-slash',
      rules: {
        length: false,
        uppercase: false,
        lowercase: false,
      },
      passwordTooltip: false,
      disabledPasswordTooltip: false,
      formMessage: null,
    };
  },
  watch: {
    rules: {
      handler(newVal, oldVal) {
        if (Object.values(newVal).includes(false)) {
          this.passwordTooltip = true;
          this.disabledPasswordTooltip = false;
        } else {
          this.disabledPasswordTooltip = true;
          this.passwordTooltip = false;
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.retievePrefectures();
  },

  methods: {
    getGender,
    retievePrefectures() {
      let _this = this;

      _this.prefectures.loader = true;

      globalService_GetAllPrefectures()
        .then((response) => {
          let data = response.data;

          let prefectures = [];
          prefectures.push({
            value: null,
            text: '都道府県を選択してください',
            disabled: true,
          });

          data.forEach((el) => {
            prefectures.push({
              value: el.id,
              text: el.name,
            });
          });

          _this.prefectures.options = prefectures;

          _this.prefectures.loader = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    formatDate(date) {
      return moment(date).format('YYYY-MM-DD');
    },

    showPasswordToggle(element) {
      let _element = document.querySelector(`#${element}`);

      if (_element.getAttribute('type') === 'password') {
        _element.setAttribute('type', 'text');

        if (element === 'current-password') {
          this.currentPasswordIcon = 'eye';
        }

        if (element === 'new-password') {
          this.newPasswordIcon = 'eye';
        }

        if (element === 'confirm-password') {
          this.confirmPasswordIcon = 'eye';
        }
      } else {
        _element.setAttribute('type', 'password');

        if (element === 'current-password') {
          this.currentPasswordIcon = 'eye-slash';
        }

        if (element === 'new-password') {
          this.newPasswordIcon = 'eye-slash';
        }

        if (element === 'confirm-password') {
          this.confirmPasswordIcon = 'eye-slash';
        }
      }
    },

    hintChecker() {
      this.formMessage = null;

      if (this.newPassword !== null && this.newPassword.length > 0) {
        if (this.newPassword.length >= 8) {
          this.rules.length = true;
        } else {
          this.rules.length = false;
        }

        if (/[A-Z]/.test(this.newPassword)) {
          this.rules.uppercase = true;
        } else {
          this.rules.uppercase = false;
        }

        if (/[a-z]/.test(this.newPassword)) {
          this.rules.lowercase = true;
        } else {
          this.rules.lowercase = false;
        }

      } else {
        this.rules.length = false;
        this.rules.uppercase = false;
        this.rules.lowercase = false;
      }
    },

    validateCreateUser(e) {
      let _this = this;

      e.preventDefault();

      _this.formMessage = null;

      _this.$store.state.modalLoaderMessage = '保存中';
      _this.$bvModal.show('modal-loader');

      if (_this.firstName !== null) {
        if (_this.lastName !== null) {
          if (_this.birthDate !== null) {
            if (_this.gender !== null) {
              if (_this.prefectures.value !== null) {
                if (_this.email !== null) {
                  if (_this.newPassword === _this.confirmPassword) {
                    if (_this.newPassword.length > 7) {
                      if (/[A-Z]/.test(_this.newPassword)) {
                        if (/[a-z]/.test(_this.newPassword)) {
                          _this.createUser();
                        } else {
                          setTimeout(() => {
                            _this.$bvModal.hide('modal-loader');
                          }, 500);

                          _this.formMessage =
                            'パスワードは1文字以上小文字が必要です。';

                          return false;
                        }
                      } else {
                        setTimeout(() => {
                          _this.$bvModal.hide('modal-loader');
                        }, 500);

                        _this.formMessage =
                          'パスワードは1文字以上大文字が必要です。';

                        return false;
                      }
                    } else {
                      setTimeout(() => {
                        _this.$bvModal.hide('modal-loader');
                      }, 500);

                      _this.formMessage =
                        'パスワードは8文字以上必要です。';

                      return false;
                    }
                  } else {
                    setTimeout(() => {
                      _this.$bvModal.hide('modal-loader');
                    }, 500);

                    _this.formMessage =
                      'パスワード確認が一致しません。';

                    return false;
                  }
                } else {
                  _this.formMessage = 'メールアドレスが必要です。';

                  setTimeout(() => {
                    _this.$bvModal.hide('modal-loader');
                  }, 500);
                }
              } else {
                _this.formMessage = '都道府県が必要です。';

                setTimeout(() => {
                  _this.$bvModal.hide('modal-loader');
                }, 500);
              }
            } else {
              _this.formMessage = '性別が必要です。';

              setTimeout(() => {
                _this.$bvModal.hide('modal-loader');
              }, 500);
            }
          } else {
            _this.formMessage = '生年月日が必要です。';

            setTimeout(() => {
              _this.$bvModal.hide('modal-loader');
            }, 500);
          }
        } else {
          _this.formMessage = '姓が必要です。';

          setTimeout(() => {
            _this.$bvModal.hide('modal-loader');
          }, 500);
        }
      } else {
        _this.formMessage = '名が必要です';

        setTimeout(() => {
          _this.$bvModal.hide('modal-loader');
        }, 500);
      }
    },

    createUser() {
      let _this = this;

      let accessToken = localStorage.getItem('_accessToken');

      let userAuthCredentials = {
        email: _this.email,
        first_name: _this.firstName,
        password: _this.newPassword,
        password_confirmation: _this.confirmPassword,
        token: accessToken,
      };
      adm_userService_CreateUserAdmin(userAuthCredentials)
        .then((response) => {
          let data = response.data.result;

          globalService_RegisterProfile({
            id: data.user.id,
            account_type: 0,
            first_name: _this.firstName,
            last_name: _this.lastName,
            gender: _this.gender,
            birth_date: this.formatDate(_this.birthDate),
            prefectures: _this.prefectures.value,
          })
            .then((response) => {
              setTimeout(() => {
                _this.$bvModal.hide('modal-loader');
                _this.$bvModal.show('user-create-success-modal');
              }, 1500);
            })
            .catch((error) => {
              console.log(error);

              _this.formMessage =
                'ユーザの作成中にエラーが起こりました。アカウントが既に存在するか、システムエラーです。再度お試しください。';

              setTimeout(() => {
                _this.$bvModal.hide('modal-loader');
              }, 1500);
            });
        })
        .catch((error) => {
          console.log(error);

          _this.formMessage =
            'ユーザの作成中にエラーが起こりました。アカウントが既に存在するか、システムエラーです。再度お試しください。';

          setTimeout(() => {
            _this.$bvModal.hide('modal-loader');
          }, 1500);
        });
    },
  },
};
</script>

<style scoped>
/* Init Style */
/* Custom Style */
</style>
